import {
  InferProps,
  arrayOf,
  oneOfType,
  shape,
  string,
  number,
  bool,
  func,
} from "prop-types";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import type {
  Payload,
  HorizontalAlignmentType,
  VerticalAlignmentType,
} from "recharts/types/component/DefaultLegendContent";

const propTypes = {
  innerData: arrayOf(shape({ name: string, value: number })).isRequired,
  outerData: arrayOf(shape({ name: string, value: number })).isRequired,
  width: number.isRequired,
  height: number.isRequired,
  label: oneOfType([bool, func]).isRequired,
  labelLine: bool.isRequired,
  outerFillColor: string.isRequired,
  getInnerFill: func.isRequired,
  legend: bool,
};

type FormatterType = {
  value: string;
  entry: any;
};

type RechartTypes = {
  legendPayload: Payload[];
  legendAlign: HorizontalAlignmentType;
  legendVerticalAlign: VerticalAlignmentType;
  legendFormatter: (props: FormatterType) => JSX.Element;
};

type PieChartDoubleLayerProps = InferProps<typeof propTypes> & RechartTypes;

const defaultInnerFill = () => "#8884d8";

const PieChartDoubleLayer = ({
  innerData,
  outerData,
  width = 250,
  height = 250,
  label = false,
  labelLine = false,
  outerFillColor = "#82ca9d",
  getInnerFill = defaultInnerFill,
  legend = false,
  legendPayload,
  legendAlign = "left",
  legendVerticalAlign = "bottom",
  legendFormatter,
}: PieChartDoubleLayerProps) => (
  <ResponsiveContainer width="100%" height="100%">
    <PieChart width={width} height={height}>
      <Pie
        data={outerData}
        dataKey="value"
        cx="50%"
        cy="50%"
        innerRadius={55}
        outerRadius={70}
        fill={outerFillColor}
        label
      />
      <Pie
        data={innerData}
        dataKey="value"
        cx="50%"
        cy="50%"
        outerRadius={50}
        labelLine={labelLine}
        label={label}
      >
        {innerData.map((entry, index) => (
          <Cell key={`inner-cell-${index}`} fill={getInnerFill(entry)} />
        ))}
      </Pie>
      <Tooltip />
      {(legend || legendPayload.length > 0) && (
        <Legend
          payload={legendPayload}
          align={legendAlign}
          verticalAlign={legendVerticalAlign}
          height={36}
          formatter={legendFormatter}
        />
      )}
    </PieChart>
  </ResponsiveContainer>
);

PieChartDoubleLayer.propTypes = propTypes;

export default PieChartDoubleLayer;
