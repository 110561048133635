import React, { Suspense, LazyExoticComponent } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Store } from "redux";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/provider";

import { rootStore } from "@thucydides/app-utils";
import { useNavState } from "@thucydides/app-nav";

import chakraTheme from "./chakraTheme";
import * as Layout from "./styles";

type AdditionalWrapperProps = { children: React.ReactChildren };

type DefaultWrapperProps = {
  children: any;
  AdditionalWrappers?: (props: AdditionalWrapperProps) => JSX.Element;
};

type AppRoute = {
  exact?: boolean;
  path: string;
  Component: LazyExoticComponent<<T>(props: T) => JSX.Element>;
};

type BootStrapperProps = {
  appName: string;
  routes: AppRoute[];
  AdditionalWrappers?: (props: AdditionalWrapperProps) => JSX.Element;
  store: Store;
};

const DefaultWrapper = ({
  children,
  AdditionalWrappers,
}: DefaultWrapperProps) => {
  if (AdditionalWrappers) {
    return <AdditionalWrappers>{children}</AdditionalWrappers>;
  }
  return children;
};

const BootStrapper = ({
  appName,
  routes,
  AdditionalWrappers,
  store,
}: BootStrapperProps) => {
  const [{ isMenuOpen }] = useNavState();
  if (store) {
    rootStore.add(appName, store);
  }

  return (
    <ChakraProvider theme={chakraTheme}>
      <Provider store={rootStore.store}>
        <DefaultWrapper AdditionalWrappers={AdditionalWrappers}>
          <Layout.Container menuOpen={isMenuOpen}>
            <Router basename="/">
              <Routes>
                {routes.map(({ path, Component }) => {
                  const routeKey = `app-${appName}-${path}`;
                  return (
                    <Route
                      key={routeKey}
                      path={`/${appName}${path}`}
                      element={
                        <Suspense fallback={null}>
                          <Component />
                        </Suspense>
                      }
                    />
                  );
                })}
              </Routes>
            </Router>
          </Layout.Container>
        </DefaultWrapper>
      </Provider>
    </ChakraProvider>
  );
};

export default BootStrapper;
