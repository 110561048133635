import { MouseEvent, useState, useCallback } from "react";
import {
  InferProps,
  arrayOf,
  oneOfType,
  shape,
  string,
  number,
  bool,
  func,
} from "prop-types";
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from "recharts";

type payload = {
  name: string;
};

type PieProps = {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  maxRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
};

type ActiveShapeProps<T> = PieProps & {
  midAngle: number;
  percent: number;
  value: number;
  payload: T;
};

const renderActiveShape = <Payload extends payload>(
  props: ActiveShapeProps<Payload>
) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const eyPadding = 4;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={sx + (cos >= 0 ? 1 : -1) * 10}
        y={ey - eyPadding}
        textAnchor={textAnchor}
        fill="#333"
        fontSize=".95em"
      >{`Votes ${value}`}</text>
      <text
        x={sx + (cos >= 0 ? 1 : -1) * 10}
        y={ey - eyPadding}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        fontSize=".8em"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const propTypes = {
  data: arrayOf(shape({ name: string, value: number })).isRequired,
  width: oneOfType([number, string]).isRequired,
  height: oneOfType([number, string]).isRequired,
  label: oneOfType([bool, func]).isRequired,
  labelLine: bool.isRequired,
  outerFillColor: string.isRequired,
  getInnerFill: func.isRequired,
  legend: bool,
};

type ActiveShapePieChartProps = InferProps<typeof propTypes>;

const PieChartActiveShape = ({
  data,
  width = "100%",
  height = "75%",
  getInnerFill,
}: ActiveShapePieChartProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback((_: MouseEvent, index: number): void => {
    setActiveIndex(index);
  }, []);

  const memoRenderActiveShape = useCallback(renderActiveShape, []);

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={memoRenderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`inner-cell-${index}`} fill={getInnerFill(entry)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartActiveShape;
