import { css } from "@emotion/react";
import styled from "@emotion/styled";

const menuIsOpen = css`
  width: calc(100% - 175px);
  margin-left: 175px;
`;

const menuIsClosed = css`
  width: 100%;
`;

const Container = styled.div`
  height: calc(100% - 40px);
  padding-top: 40px;

  ${(props: { menuOpen: boolean }) =>
    props.menuOpen ? menuIsOpen : menuIsClosed};
`;

export { Container };
